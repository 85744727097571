export const maskdoc = (v) => {
  if (v == undefined) {
    return v;
  }

  if (v.length == 0) {
    return v;
  }

  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return v;
};

export const formatarChaveDeAcessoNfe = (chave) => {
  if (!chave) return;
  if (chave.length !== 44) return chave;
  else {
    return chave.split('').reduceRight(function (elemento, anterior) {
      var temp = anterior + elemento;
      if (temp.replace(/\s/g, '').length % 4 === 0) return ' ' + temp;
      else return temp;
    });
  }
};

export const maskphone = (v) => {
  if (v == undefined) {
    return v;
  }

  if (v.length == 0) {
    return v;
  }

  if (v === '0') {
    return v;
  } else {
    var r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      // 11+ digits. Format as 5+4.
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      // 6..10 digits. Format as 4+4
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      // 3..5 digits. Add (0XX..)
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      // 0..2 digits. Just add (0XX
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }
};

export const maskCEP = (str) => {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  if (re.test(str)) {
    return str.replace(re, '$1.$2-$3');
  } else {
    return str;
  }
};

export const formataData = (dt) => {
  if (!dt || dt.length === 0) {
    return dt;
  }

  if(dt.length === 10){
    dt = `${dt}T12:00:00`;
  }

  if (String(dt).includes('T00:00:00')) {
    dt = dt.replace('T00:00:00', 'T12:00:00');
  }

  const dataUTC = new Date(dt);

  return dataUTC.toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const formataHora = (dt) => {
  if (!dt || dt.length === 0) {
    return '';
  }

  const dataUTC = new Date(dt);

  return dataUTC.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const formataMoeda = (numero, decimais, prefixo) => {
  decimais = decimais || 4;
  var symbol = prefixo;
  var decimal = ',';
  var thousand = '.';
  var negative = numero < 0 ? '-' : '';
  var i = parseInt((numero = Math.abs(+numero || 0).toFixed(decimais)), 10) + '';
  var j = 0;

  decimais = !isNaN((decimais = Math.abs(decimais))) ? decimais : 2;
  symbol = symbol !== undefined ? symbol : '$';
  thousand = thousand || ',';
  decimal = decimal || '.';
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (decimais
      ? decimal +
        Math.abs(numero - i)
          .toFixed(decimais)
          .slice(2)
      : '')
  );
};

export function maskVersao(versao) {
  if (versao == undefined) {
    return versao;
  }
  versao = versao.replace(/[^0-9.]/g, '');
  // versao = versao.replace(/[^0-9]/g, '');

  // if (versao.length > 4 && versao.length < 6) {
  //   versao = versao.substring(0, 4) + '.' + versao.substring(4, 5);
  //   return versao;
  // }
  // if (versao.length > 5 && versao.length < 7) {
  //   versao = versao.substring(0, 4) + '.' + versao.substring(4, 5) + '.' + versao.substring(5, 6);
  //   return versao;
  // }
  // if (versao.length > 6 && versao.length < 8) {
  //   versao = versao.substring(0, 4) + '.' + versao.substring(4, 5) + '.' + versao.substring(5, 6) + '.' + versao.substring(6, 7);
  //   return versao;
  // }

  // if (versao.length > 7 && versao.length < 9) {
  //   if (versao[4] === '1') {
  //     versao = versao.substring(0, 4) + '.' + versao.substring(4, 6) + '.' + versao.substring(6, 7) + '.' + versao.substring(7);
  //     return versao;
  //   }
  //   versao = versao.substring(0, 4) + '.' + versao.substring(4, 5) + '.' + versao.substring(5, 7) + '.' + versao.substring(7);
  //   return versao;
  // }

  // if (versao.length > 8) {
  //   versao = versao.substring(0, 4) + '.' + versao.substring(4, 6) + '.' + versao.substring(6, 8) + '.' + versao.substring(8);
  // }

  return versao;
}
